<template>
  <div class="reg">
    <div class="reg-header">
      <topNav></topNav>
    </div>
    <div class="reg-main">
      <div class="m-steps">
        <Steps :current="curIndex">
          <Step title="Email verification"></Step>
          <Step title="Add your Profile"></Step>
          <Step title="Registration completed"></Step>
        </Steps>
      </div>

      <div class="admin-add">
        <div class="admin-add-content">
          <Form class="F" ref="formInline" :model="formInline" :rules="ruleInline" @keyup.enter.native="handleSubmit('formInline')">
            <FormItem label="Name：" :label-width="260" prop="name">
              <Input v-model="formInline.name" type="text" placeholder="Please enter the name" style="width: 300px" />
            </FormItem>
            <FormItem label="Password：" :label-width="260" prop="passWord" v-if="!login">
              <Input v-model="formInline.passWord" type="text" placeholder="Please enter your password" style="width: 300px" maxlength="20" minlength="6" />
            </FormItem>
            <FormItem label="E-mail：" :label-width="260" prop="email">
              <Input v-model="formInline.email" disabled type="text" placeholder="Please enter your e-mail" style="width: 300px" />
            </FormItem>
            <FormItem label="Company：" :label-width="260" prop="company">
              <Input v-model="formInline.company" type="text" placeholder="Please enter your company" style="width: 300px" />
            </FormItem>
            <FormItem label="Job title：" :label-width="260" prop="jobTitle">
              <Input v-model="formInline.jobTitle" type="text" placeholder="Please enter your job title" style="width: 300px" />
            </FormItem>
            <FormItem label="Mobile：" :label-width="260">
              <Input v-model="formInline.mobile" type="text" placeholder="Please enter your mobile" style="width: 300px" />
            </FormItem>
            <FormItem label="Region：" :label-width="260" prop="region">
              <Select v-model="formInline.region" style="width:300px" @on-change="onConfirm">
                <Option v-for="item in country" :value="item.nameEn" :key="item.nameEn">{{ item.nameEn }}</Option>
              </Select>
            </FormItem>
            <FormItem label="Interest:" :label-width="260" prop="category" v-if="categoryList" :required="true">
              <Row v-for="(item, index) in formInline.category" :key="cateKey + '' + index" style="margin-bottom: 5px;">
                <Col span="10">
                <Cascader v-model="formInline.category[index]" filterable :data="categoryList" :key="'categoryArr' + index" :ref="'categoryArr' + index" @on-change="changeCategoryArr()"></Cascader>
                </Col>
                <Col span="2" style="text-align: center">
                <Icon type="ios-add-circle-outline" v-if="index === 0" @click="addCategoryArrr()" style="color: #2d8cf0; font-size: 20px; cursor: pointer" />
                <Icon type="ios-remove-circle-outline" v-else style="color: red; font-size: 20px; cursor: pointer" @click="delCategoryArrr(index)" />
                </Col>
              </Row>
            </FormItem>
            <FormItem :label-width="260">
              <Button type="primary" @click="handleSubmit('formInline')" size="large" style="width: 300px" class="btn">next</Button>
            </FormItem>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { validator } from "@/other/validator";
import topNav from "./components/top";
import provincial from "@/assets/js/provinces.json"; //省市区json

export default {
  name: "reg",
  data() {
    const PhoneValid = (rule, value, callback) => {
      if (!value) {
        callback();
        return;
      }
      if (/^[1][0-9]{10}$/.test(value)) {
        callback();
      } else {
        callback("The validation failed");
      }
    };

    return {
      cateKey: 0,
      numCode: "", // 手机验证码
      timer: null,
      showCode: true,
      loading: false,
      count: "",
      codeKey: "",

      categoryList: [],
      curIndex: 1,

      formInline: {
        name: "",
        passWord: "",
        email: "",
        mobile: "",
        company: "",
        jobTitle: "",
        region: "",
        category: [],
      },
      flagStyle: "",

      ruleInline: {
        name: [
          { required: true, message: "Please enter the Name", trigger: "blur" },
        ],
        passWord: [
          {
            required: true,
            message: "Please enter your password",
            trigger: "blur",
          },
          {
            type: "string",
            max: 20,
            message: "not exceed 20",
            trigger: "blur",
          },
          {
            type: "string",
            min: 6,
            message: " no fewer than 6",
            trigger: "blur",
          },
        ],
        //mobile: [{ validator: PhoneValid, trigger: "blur" }],
        // email: [{required: true, message: "Please enter your e-mail", trigger: "blur" }],
        // code: [{ required: true, message: "验证码不能为空", trigger: "blur" }],
        company: [
          {
            required: true,
            message: "Please enter your company",
            trigger: "blur",
          },
        ],
        jobTitle: [
          {
            required: true,
            message: "Please enter your job title",
            trigger: "blur",
          },
        ],
        region: [
          {
            required: true,
            message: "Please enter your region",
            trigger: "blur",
          },
        ],
      },
      count: "",
      captchaImg: "",
      userMsg: "",
      showModel: false,
      areaList: [], //地址数据
      categoryList: [], // 类别数据
    };
  },
  components: {
    topNav,
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      regOrigin: "getRegOrigin",
      user: "getUser",
      country: "getCountry",
      login: "getLogin",
      token: "getToken",
    }),
    isCover() {
      return this.$route.query.isCover == "true" ? true : false;
    },
    email() {
      return this.$route.query.email;
    },
    edit() {
      if (this.$route.query.edit) {
        return this.$route.query.edit;
      }
    },
  },
  created() {
    this.areaList = provincial.data;
    this.init();
  },
  methods: {
    ...mapActions({
      updatePhonenumber: "user/updatePhonenumber",
      validatePhone: "loginModule/validatePhone",
      getCategory: "getCategory",
      graphqlPost: "graphqlPost",
      getMsgCode: "getMsgCode",
      validateCode: "validateCode",
      regUser: "regUser",
      regUserByVisitor: "regUserByVisitor",
      addReg: "en/addReg",
      patchUserCategory: "user/patchUserCategory",
      editReg: "en/editReg",
      getToken: "loginModule/getToken",
      getExhibitionInfo: "loginModule/getExhibitionInfo",
    }),
    ...mapMutations({
      setLoginType: "setLoginType",
      setUser: "setUser",
      setUserId: "setUserId",
      setToken: "setToken",
      setTokenTime: "setTokenTime",
      setLogin: "setLogin",
      setExhibitionId: "setExhibitionId",
      setLoginBg: "setLoginBg",
    }),
    init() {
      if (this.user && this.user.inMember) {
        let city = [];
        if (this.user.province) {
          city.push(this.user.province);
          if (this.user.city) {
            city.push(this.user.city);
          }
          if (this.user.county) {
            city.push(this.user.county);
          }
        }
        let category =
          this.user && this.user.inMemberId && this.user.inMember.category
            ? this.user.inMember.category
            : [];
        this.formInline = {
          name: this.user.name || "",
          passWord: "",
          phone: this.user.phoneText || this.user.phone,
          email: this.user.email,
          company: this.user.company,
          jobTitle: this.user.jobTitle,
          city: city,
          region: this.user.country,
          category: category,
        };
      } else {
        this.formInline.email = this.email;
      }
      this.initCate();
    },
    async initCate() {
      let res = await this.getCategory();
      if (res && res.categories) {
        let ProCategory = JSON.parse(JSON.stringify(res.categories));
        ProCategory.map((c) => {
          c.value = c.nameEn || c.name;
          c.label = c.nameEn || c.name;
          if (c.childrens && c.childrens.length > 0) {
            c.children = c.childrens;
            c.children.map((v) => {
              v.value = v.nameEn || v.name;
              v.label = v.nameEn || v.name;
              if (v.childrens && v.ichildrens.length > 0) {
                v.children = v.childrens;
                v.children.map((z) => {
                  z.value = z.nameEn || z.name;
                  z.label = z.nameEn || z.name;
                });
              }
            });
          }
        });
        this.categoryList = ProCategory;
        let typeList = [];
        this.formInline.category = [];
        let array = [];
        if (this.user && this.user.inMember && this.user.inMember.category) {
          typeList = this.user.inMember.category.split(";");
          console.log(this.typeList, "更新type");
          typeList.forEach((c) => {
            if (c.indexOf(">")) {
              c = c.split(">");
            }
            array.push(c);
          });
        } else {
          typeList = [];
          array.push(typeList);
        }
        this.formInline.category = array;
      }
    },
    addCategoryArrr() {
      if (this.formInline.category.length >= 5) {
        this.$Message.warning(`No more than 5`);
        return;
      }
      this.formInline.category.push([]);
      this.cateKey += 1;
    },
    delCategoryArrr(i) {
      console.log(i);
      this.cateKey += 1;
      this.formInline.category.splice(i, 1);
      this.$nextTick(() => {
        this.formInline.category = JSON.parse(
          JSON.stringify(this.formInline.category)
        );
      });
    },
    changeCategoryArr() {
      setTimeout((c) => {
        this.$refs["formInline"].validateField("category");
      }, 400);
    },
    validForm(name) {
      // 验证表单
      return new Promise((resolve, reject) => {
        this.$refs[name].validate((valid) => {
          resolve(valid);
        });
      });
    },
    async validMsgCode() {
      // 验证码校验
      if (!this.formInline.code) return false;
      if (!this.codeKey) {
        this.$Message.warning("Get verification code!");
        return false;
      }
      let valid = await this.validateCode({
        key: this.codeKey,
        code: this.formInline.code,
      });
      if (!valid) {
        this.$Message.error("验证码未通过!");
        return false;
      }
      return true;
    },
    async downTime() {
      const valid = await this.getMsgCode(this.formInline.phone);
      if (valid && valid.result) {
        this.codeKey = valid.data.key;
      }
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.showCode = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.showCode = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    async getCaptcha() {
      let phone = this.formInline.phone;
      if (!phone) {
        let txt = "";
        if (!txt) {
          txt = "Please enter the mobile";
        } else if (/^[1][0-9]{10}$/.test(phone)) {
          txt = "";
        } else {
          txt = "请输入正确的手机号码";
        }
        if (txt) {
          this.$Message.error({ content: txt, duration: 2 });
        }
        return;
      }
      let res = await this.validatePhone(phone);
      if (res && res.data) {
        this.$Message.error({
          content: `手机号（${phone}）已绑定其他账号`,
          duration: 2,
        });
        return;
      } else {
        this.downTime();
      }
    },
    async handleSubmit(name) {
      let valid = await this.validForm(name); // 验证表单
      if (!valid) return;
      this.onSubmit();
    },
    onConfirm(e) {
      this.country.forEach((c) => {
        // 选择国旗
        if (c.nameEn == e) {
          this.flagStyle = c.shortNameEn;
        }
      });
      console.log(this.flagStyle, "this.form.flagStyle");
    },
    async onSubmit(values) {
      let category = [];
      if (this.formInline.category.length > 0) {
        this.formInline.category.forEach((c) => {
          if (c.length > 1) {
            c = c.join(">");
          } else {
            c = c[0];
          }
          category.push(c);
        });
      }
      if (category.join(";") === "") {
        this.$Message.warning("Please select category");
        return;
      }
      let body = {
        name: this.formInline.name,
        email: this.formInline.email,
        category: category.join(";"),
        jobTitle: this.formInline.jobTitle,
        company: this.formInline.company,
        exhibitionId: this.exhibitionId,
        country: this.formInline.region,
        flagStyle: this.flagStyle,
        originLang: "en",
        isCover: this.isCover,
      };
      if (!this.login) {
        body.password = this.formInline.passWord;
      } else {
        body.inMemberId = this.user.inMember.id;
      }
      if (!this.login) {
        let result = await this.editReg(body);
        if (result.result) {
          this.$Message.success("success");
          this.regFinish();
        } else {
          // 注册失败
          this.$Message.error(result.message);
        }
      } else {
        body.isCover = true;
        let result = await this.addReg(body);
        if (result.result) {
          // 注册成功
          this.$Message.success("success");
          this.regFinish();
        } else {
          // 注册失败
          this.$Message.error(result.message);
        }
      }
    },
    async reg() {
      let category = [];
      if (this.formInline.category.length > 0) {
        this.formInline.category.forEach((c) => {
          if (c.length > 1) {
            c = c.join(">");
          } else {
            c = c[0];
          }
          category.push(c);
        });
        this.formInline.category = category.join(";");
      }
      let body = {
        name: this.formInline.name,
        password: this.formInline.passWord,
        phone: this.formInline.phone,
        jobTitle: this.formInline.jobTitle,
        company: this.formInline.company,
        province: this.formInline.city[0] || "",
        city: this.formInline.city[1] || "",
        county: this.formInline.city[2] || "",
        exhibitionId: this.exhibitionId,
        isCover: this.isCover ? true : false,
        category: this.formInline.category,
        originType: this.regOrigin ? this.regOrigin.originType : "",
        originId: this.regOrigin ? this.regOrigin.originId : "",
        originRemark: this.regOrigin ? this.regOrigin.originRemark : "",
        originLang: "zh-en",
      };
      console.log(body, "---");
      let res = false;
      if (this.user && this.user.userId) {
        body = {
          inMemberId: this.user.inMember.id,
          exhibitorId: this.exhibitionId,
          name: this.formInline.name,
          jobTitle: this.formInline.jobTitle,
          company: this.formInline.company,
          province: this.formInline.city[0] || "",
          city: this.formInline.city[1] || "",
          county: this.formInline.city[2] || "",
          category: this.formInline.category,
          originType: this.regOrigin ? this.regOrigin.originType : "",
          originId: this.regOrigin ? this.regOrigin.originId : "",
          originRemark: this.regOrigin ? this.regOrigin.originRemark : "",
          originLang: "zh-en",
        };
        res = await this.regUserByVisitor(body);
      } else {
        res = await this.regUser(body);
      }
      console.log(res, "====");
      if (res.result) {
        this.$router.push(`/regFinish/${this.exhibitionId}`);
      } else {
        this.$Message.error(res.message);
      }
    },
    async regFinish() {
      if (this.login) {
        this.$router.push(`/regFinish/${this.exhibitionId}`);
        return;
      }

      let orgin = location.host.split(".").reverse()[1];
      let clientId =
        orgin === "zhanshangxiu"
          ? "synair-showonline-js"
          : `synair-${orgin}-js`;
      let opt = {
        client_id: clientId,
        client_secret: "synair-showonline-js",
        grant_type: "email",
        scope: "openid profile showonline-api bmm-api phone",
        email: this.formInline.email,
        password: this.formInline.passWord,
      };
      let tokenData = await this.getToken(opt);
      if (tokenData) {
        this.setToken(tokenData.access_token);
        this.setTokenTime(tokenData.expires_in * 1000 + new Date().getTime());
        this.setExhibitionId(this.exhibitionId);
        await this.getExhibitionInfo(this.exhibitionId);
        this.setLoginType(1);
        this.getUser();
      }
    },
    async getUser() {
      let query = `
        query{
          memberQuery{
            current{
              address
              avatarUrl
              balance
              birthday
              businessCard
              city
              company 
              country
              county
              createAt
              deleteAt
              department
              email
              emailConfirmed
              followCount
              id
              inMember(exhibitionId:"${this.exhibitionId}"){ 
                business
                category
                id 
                memberId
                originId
                originLang
                originRemark
                originType
                regAt
                sayCount
                sayUserCount
                userId
                userRole
                exhibitorId
                companyUsers{
                  id
                  isAppointment
                  company {
                    nameEn
                  }
                }
              }
              introduction
              isDeleted
              jobTitle
              jobTitleEn
              name
              nameEn
              nickName
              phone
              phoneConfirm
              province
              talkCount
              talkSuccessedCount
              title
              updateAt
              userId
            }
          } 
        }
      `;
      let opt = {
        opt: {
          query: query,
        },
        token: this.token,
      };
      let data = await this.graphqlPost(opt);
      if (data.data && data.data.memberQuery && data.data.memberQuery.current) {
        this.setLogin(true);
        this.setUser(data.data.memberQuery.current);
        this.setUserId(data.data.memberQuery.current.userId);
        this.$router.push(`/regFinish/${this.exhibitionId}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.reg {
  &-header {
    box-shadow: 0px 2px 3px 0px rgba(101, 101, 101, 0.1);
  }
  &-main {
    width: 1200px;
    margin: 50px auto;
    .m-steps {
      width: 710px;
      margin: 0 auto;
      ::v-deep .ivu-steps-item {
        .ivu-steps-head {
          background: transparent;
        }
      }
    }
  }
  .F {
    display: flex;
    flex-direction: column;
    width: 800px;
    height: 380px;
    position: relative;
    margin: 0 auto;
    &-1 {
      margin-top: 34px;
      margin-bottom: 0;
      &.phone_code {
        ::v-deep {
          .ivu-form-item-content {
            display: flex;
            .ivu-btn {
              margin-left: 10px;
            }
          }
        }
      }
      .I {
        // margin-top: 19px;
      }
    }
    .F-2 {
      width: 100%;
      height: 15px;
      line-height: 15px;
      display: flex;
      justify-content: space-between;
      margin: 24px 0;
      .F-2-text {
        width: 48px;
        height: 13px;
        font-size: 12px;
        color: #333;
        cursor: pointer;
      }
    }
    .wx-btn {
      margin-top: 20px;
    }
    .code {
      width: 70px;
      height: 26px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 8px;
      cursor: pointer;
    }
    .btn {
      margin: 34px auto 0;
    }
  }
}
.admin-add {
  margin-top: 34px;
}
.service-code {
  position: relative;
  width: 300px;
  .code-btn1 {
    position: absolute;
    top: 4px;
    right: 7px;
    height: 28px;
    padding: 0;
    @include font_color(#1890ff);
  }
  .code-btn2 {
    position: absolute;
    top: 4px;
    right: 7px;
    height: 28px;
    padding: 0;
  }
}
</style>
